export const menuData = {
    roleList:[],
    menuList :[
        {
            name: '汇总统计',
            router: '/course/home',
            key: 1,
        },
        {
            name: '行情展示',
            router: '/course/summary',
            key:2,
            children:[
                {name: '股票',router: '/course/summary/1',key:11,},
                {name: '债券',router: '/course/summary/2',key:12,},
                {name: '期货',router: '/course/summary/3',key:13,},
            ]
        },
        {
            name: '组合管理',
            router: '/course/group',
            key:3,
        },
        {
            name: '交易管理',
            router: '/course/trade',
            key:4,
            children:[
                {name: '股票',router: '/course/trade/1',key:14,},
                {name: '债券',router: '/course/trade/2',key:15,},
                {name: '期货',router: '/course/trade/3',key:16,},
            ]
        },
        {
            name: '报告管理',
            router: '/course/report',
            key:5,
            children:[
                {name: '报告编写',router: '/course/report/edit',key:5-1,},
                {name: '草稿箱',router: '/course/report/draft',key:5-2,},
                {name: '状态展示',router: '/course/report/state',key:5-3,},
                {name: '报告浏览器',router: '/course/report/browser',key:5-4,},
            ]
        },
        {
            name: '报告管理',
            router: '/course/report',
            key:6,
            children:[
                {name: '草稿箱',router: '/course/report/draft',key:20,},
                {name: '状态展示',router: '/course/report/state',key:21,},
                {name: '报告浏览器',router: '/course/report/browser',key:22,},
            ]
        },
        {
            name: '成员',
            router: '/course/user',
            key:7,
            children:[
                {name: '管理',router: '/course/user/account',key:17,},
                {name: '历史记录',router: '/course/user/jobChange',key:18,},
            ]
        },
        {
            name: '历史记录',
            router: '/course/user/jobChange',
            key: 8,
        },
        {
            name: '调仓管理',
            router: '/course/stock',
            key:9,
        },
        {
            name: '风控管理',
            router: '/course/control',
            key:10,
        },
        // {
        //     name: '开放式基金',
        //     router: '/course/fund',
        //     key:11,
        // },
    ],
    routerType: false,
    direction: true,
}